import {
  SYSTEM_ID_ENERGY_DEMO,
  SYSTEM_ID_ENERGY_DEMO_UK,
  SYSTEM_ID_ENERGY_US_DEMO_1,
  SYSTEM_ID_ENERGY_US_DEMO_2,
  SYSTEM_ID_ENERGY_US_DEMO_3,
  SYSTEM_ID_PERCIVAL,
} from '@twaice-fe/shared/constants';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, combineLatest, map, tap, Observable } from 'rxjs';
import { getQueryParamAsDate, TimeLength, TimeRangeEnum } from '@twaice-fe/shared/utilities';
import { TimeRangeInterface } from '@twaice-fe/shared/models';
import { subDays } from 'date-fns';
import { ActivatedRoute } from '@angular/router';

const { systemActions } = actions;
const { systemSelectors } = selectors;

type TimeRangeConfig = Record<string, Date>;

const defaultStartDates = {
  [SYSTEM_ID_PERCIVAL]: new Date('2022-10-20T00:00:00'),
  [SYSTEM_ID_ENERGY_DEMO]: new Date('2022-09-01T00:00:00'),
  [SYSTEM_ID_ENERGY_DEMO_UK]: new Date('2023-12-01T00:00:00+00:00'),
  [SYSTEM_ID_ENERGY_US_DEMO_1]: new Date('2024-04-01T00:00:00+00:00'),
  [SYSTEM_ID_ENERGY_US_DEMO_2]: new Date('2024-10-01T00:00:00+00:00'),
  [SYSTEM_ID_ENERGY_US_DEMO_3]: new Date('2024-04-09T00:00:00+00:00'),
};

const timeRangeIdLookup = {
  7: 'sevenDays',
  30: 'thirtyDays',
  60: 'sixtyDays',
  90: 'ninetyDays',
  365: 'threeHundredSixtyFiveDays',
};

/**
 * This service overrides the default time range behaviour for demo systems.
 * By including this service we can set specific selected dates by default,
 * with a per-feature system/date mapping
 */
@Injectable()
export class DemoTimeRangeService {
  destroyRef = inject(DestroyRef);

  constructor(
    private store: Store,
    private route: ActivatedRoute
  ) {}

  getDemoTimeRange(
    timeRangeConfig?: TimeRangeConfig
  ): Observable<{ timeRange: TimeRangeInterface; availableTimeRanges: TimeRangeInterface[] }> {
    return combineLatest([
      this.store.select(systemSelectors.getSelected),
      this.store.select(systemSelectors.getDefaultTimeRange),
      this.route.queryParams,
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(([system]) => Boolean(system)),
      map(([{ id: systemId }, defaultTimeRange, queryParams]) =>
        this.getDefaultTimeRange(timeRangeConfig, systemId, queryParams, defaultTimeRange)
      ),
      tap(({ timeRange, availableTimeRanges }) =>
        this.store.dispatch(
          systemActions.setInitialTimeRange({
            timeRange,
            availableTimeRanges,
          })
        )
      )
    );
  }

  /**
   * For features that use a time range outside of the global store.
   * If the time range is fetched from the global store then use `setDemoTimeRange`.
   *
   * @param systemId
   * @param timeRangeConfig
   */
  getTimeRangeFromRoute(systemId: string, timeRangeConfig?: TimeRangeConfig) {
    // If it is not pre-set with input variable, we set the time-range to the query value or to the last hour
    const defaultStartDate =
      timeRangeConfig?.[systemId] ?? defaultStartDates[systemId] ?? new Date(new Date().setHours(0, 0, 0, 0));
    const from = this.route.snapshot.queryParams.from
      ? getQueryParamAsDate(this.route.snapshot.queryParams, 'from')
      : new Date(defaultStartDate - TimeLength.DAY * 6);

    const to = this.route.snapshot.queryParams.to ? getQueryParamAsDate(this.route.snapshot.queryParams, 'to') : defaultStartDate;

    return { from, to };
  }

  private getDefaultTimeRange(
    timeRangeConfig: TimeRangeConfig | undefined,
    systemId: string,
    queryParams: Record<string, string>,
    defaultTimeRange: TimeRangeEnum
  ) {
    const selectedRange = { startDate: queryParams.startDate, endDate: queryParams.endDate, id: queryParams.timeRange };
    const systemStartDate =
      timeRangeConfig?.[systemId] ?? defaultStartDates[systemId] ?? new Date(new Date().setHours(0, 0, 0, 0));

    const availableTimeRanges = this.getTimeRanges(systemStartDate);
    const timeRange = this.getTimeRange(defaultTimeRange, availableTimeRanges, selectedRange);

    return { timeRange, availableTimeRanges };
  }

  private getTimeRanges(systemStartDate: Date): TimeRangeInterface[] {
    const dateDeltas = [7, 30, 60, 90, 365];
    const ranges: TimeRangeInterface[] = dateDeltas.map((delta) => ({
      id: timeRangeIdLookup[delta] ?? null,
      value: [subDays(systemStartDate, delta), systemStartDate],
      label: `Last ${delta} days`,
      disabled: false,
    }));

    return [
      {
        id: 'custom',
        value: [systemStartDate, systemStartDate],
        label: 'custom',
        disabled: true,
      },
      ...ranges,
    ];
  }

  private getTimeRange(
    defaultTimeRange: TimeRangeEnum | undefined,
    availableTimeRanges: TimeRangeInterface[],
    selectedRange: { startDate: string; endDate: string; id: string }
  ) {
    const selectedTimeRange =
      availableTimeRanges.find((range) => range.id === selectedRange.id) ||
      availableTimeRanges[defaultTimeRange ?? TimeRangeEnum.LAST_THIRTY_DAYS];

    if (selectedTimeRange.id === 'custom') {
      if (selectedRange.startDate && selectedRange.endDate) {
        selectedTimeRange.value = [
          new Date(Number(selectedRange.startDate) * 1000),
          new Date(Number(selectedRange.endDate) * 1000),
        ];
      }
    }

    return selectedTimeRange;
  }
}
