import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { actions, selectors } from '@twaice-fe/frontend/shared/store';

import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
  EnergyLossDataInterface,
  FeatureFlagsEnum,
  OverviewSystem,
  Solution,
  StorageLevel,
  System,
} from '@twaice-fe/shared/models';
import { distinctUntilChanged, filter, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { CellConfigInterface } from '../datatable/models/cell-config.interface';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  ColumnItem,
  formatSystemStats,
  healthValuesConfig,
  safetyScoreValuesConfig,
  statsValuesConfig,
  StatsValuesConfigInterface,
  SystemOverviewAntTableInterface,
} from './system-balancing-chart-config';
import { hasFeatureFlag, LevelNameTranslationPipe, StorageLevelIdentifierPipe } from '@twaice-fe/frontend/shared/services';
import { isEqual } from '@twaice-fe/shared/utilities';

const { systemSelectors, configsSelectors, performanceManagerSelectors } = selectors;

const { systemActions } = actions;

@Component({
  selector: 'twaice-fe-system-portfolio-overview-table',
  templateUrl: 'system-portfolio-overview-table.component.html',
  styleUrls: ['./system-portfolio-overview-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe, StorageLevelIdentifierPipe, LevelNameTranslationPipe],
})
export class SystemPortfolioOverviewTableComponent implements OnInit, OnDestroy {
  @Output() rowSelected: EventEmitter<System> = new EventEmitter<System>();

  @Input() selectedSystemId?: string;
  @Input() intercomTarget?: string;

  systemData$: Observable<SystemOverviewAntTableInterface[]>;
  destroy$: Subject<void> = new Subject();

  isPerformanceManagerEnabled = false;
  systemOverviewColumns: ColumnItem<OverviewSystem>[];
  isSenecDemo = false;
  isFinancialImpactDisabled = false;

  protected readonly hasFeatureFlag = hasFeatureFlag;

  constructor(
    protected store: Store,
    private decimalPipe: DecimalPipe,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private storageLevelIdentifierPipe: StorageLevelIdentifierPipe,
    private levelNameTranslationPipe: LevelNameTranslationPipe
  ) {
    this.systemData$ = this.store.select(systemSelectors.getSystemList).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      tap(() => this.changeDetectorRef.detectChanges()),
      map((data) => this.formatSystemOverviewData(data)),
      takeUntil(this.destroy$)
    );

    this.store
      .select(configsSelectors.getAvailableSolutionList)
      .pipe(takeUntilDestroyed())
      .subscribe((solutions) => {
        this.isPerformanceManagerEnabled = solutions.includes(Solution.ENERGY);
      });
  }

  ngOnInit(): void {
    this.initConfigListener();

    this.store
      .select(configsSelectors.getConfigFeatureFlagList)
      .pipe(distinctUntilChanged(isEqual))
      .subscribe((featureFlags: FeatureFlagsEnum[]) => {
        const isSenecDemo = featureFlags.includes(FeatureFlagsEnum.SENEC_DEMO);
        const isFinancialImpactDisable = featureFlags.includes(
          FeatureFlagsEnum.ENERGY_PERFORMANCE_MANAGER_DISABLE_FINANCIAL_IMPACT
        );

        this.isSenecDemo = isSenecDemo;
        this.isFinancialImpactDisabled = isFinancialImpactDisable;
        this.systemOverviewColumns = this.createSystemOverviewColumnItems({
          includeDailyLoss: !isSenecDemo,
          includePerformanceStatus: !isSenecDemo,
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigate(systemId: string, link: string) {
    this.store.dispatch(systemActions.selectSystem({ systemId: systemId }));
    this.router.navigateByUrl(link);
  }

  onRowSelected($event: CellConfigInterface, item: System) {
    this.rowSelected.emit(item);
  }

  private initConfigListener() {
    this.store
      .select(systemSelectors.getSelectedId)
      .pipe(
        filter((systemId) => !!systemId),
        tap((systemId) => {
          this.selectedSystemId = systemId;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private createSystemOverviewColumnItems({
    includeDailyLoss,
    includePerformanceStatus,
  }: {
    includeDailyLoss: boolean;
    includePerformanceStatus: boolean;
  }): ColumnItem<OverviewSystem>[] {
    const dailyLoss = {
      name: 'Daily Loss',
      class: 'justify-center',
      width: '150px',
      tooltip: {
        link: 'https://help.twaice.cloud/en/articles/206437-energy-loss-calculation-how-and-what',
        linkText: 'Learn more about the Daily Loss',
        content:
          'This metric tracks the loss in energy and revenue due to the difference between current available energy and initial energy of the storage.' +
          ' Calculated based on one cycle per day, with no consideration of aging.' +
          ' If strings within one node can be operated independently, actual losses might be lower.' +
          ' Revenue loss is based on an market specific benchmark. Use this insight to optimize performance.',
      },
    };
    const performanceStatus = {
      name: 'Performance Status',
      tooltip: {
        content:
          'This metric indicates the percentage of nodes in a storage with balancing issues. The severity is based on the node with the highest imbalance. Use this insight to identify imbalances nodes and restore optimal performance',
      },
      class: 'justify-center',
    };

    return [
      {
        name: this.levelNameTranslationPipe.transform(this.storageLevelIdentifierPipe.transform(StorageLevel.SYSTEM, true, 1)),
        width: '223px',
      },
      includeDailyLoss ? dailyLoss : null,
      includePerformanceStatus ? performanceStatus : null,
      {
        name: 'Safety Index',
        tooltip: {
          link: 'https://help.twaice.cloud/en/articles/211666-twaice-safety-index',
          linkText: 'Learn more about the Safety Index',
          content:
            'The index reflects the current safety status of the storage, ranging from ‘Stable’, to ‘Action Recommended’ to ‘Action Required’. It is calculated based on the number of anomalous measurements for temperature and resistance. Use this index to prioritize maintenance and reduce risks.',
        },
        class: 'justify-center',
      },
      {
        name: 'State of Health Spread',
        tooltip: {
          content:
            'This metrics reflects the range between the healthiest and least healthy strings in the storage. Use this insight to address strings with lower health to ensure overall system stability.',
        },
        class: 'justify-center',
      },
    ].filter(Boolean);
  }

  private getPerformanceStatus(system): StatsValuesConfigInterface {
    const formattedStats = formatSystemStats(system.systemStatistics?.y);
    const link = `/performance-manager?kpi=${system.systemStatistics?.kpi}`;

    const highSpreadCount = Number(this.decimalPipe.transform(formattedStats.high, '1.0-0'));
    const mediumSpreadCount = Number(this.decimalPipe.transform(formattedStats.medium, '1.0-0'));
    const balancedSpreadCount = Number(this.decimalPipe.transform(formattedStats.balanced, '1.0-0'));

    const performanceIndex = highSpreadCount + mediumSpreadCount;

    if (highSpreadCount === 0 && mediumSpreadCount === 0 && balancedSpreadCount === 0) {
      return { ...statsValuesConfig.missing, link };
    }
    if (highSpreadCount > 0) {
      return { ...statsValuesConfig.high, value: performanceIndex, link };
    }
    if (mediumSpreadCount > 0) {
      return { ...statsValuesConfig.medium, value: performanceIndex, link };
    }
    if (balancedSpreadCount > 0) {
      return { ...statsValuesConfig.balanced, value: null, link };
    }
  }

  private getHealthStatusBasedOnSohSpread(system): StatsValuesConfigInterface {
    const sohSpread = system?.kpis?.sohCMax && system?.kpis?.sohCMin ? system?.kpis?.sohCMax - system?.kpis?.sohCMin : null;
    const link = `/energy/health`;

    const thresholdBalancedSpread = 5;
    const thresholdMediumSpread = 10;

    if (!sohSpread) {
      return { ...healthValuesConfig.missing, link };
    }

    if (sohSpread < thresholdBalancedSpread) {
      return { ...healthValuesConfig.balanced, link };
    } else if (sohSpread < thresholdMediumSpread) {
      return { ...healthValuesConfig.medium, label: null, value: +this.decimalPipe.transform(sohSpread, '1.0-1'), link };
    } else {
      return { ...healthValuesConfig.high, label: null, value: +this.decimalPipe.transform(sohSpread, '1.0-1'), link };
    }
  }

  private getEnergyLossImpact(system): EnergyLossDataInterface {
    let energyLossData: EnergyLossDataInterface;
    this.store
      .select(performanceManagerSelectors.getEnergyLossBySystem(system.rootContainerId))
      .pipe(takeUntil(this.destroy$))
      .subscribe((energyLoss) => {
        if (energyLoss) {
          energyLossData = { ...energyLoss };
          if (this.isFinancialImpactDisabled) {
            energyLossData.lostMoney = null;
          }
        }
      });
    return energyLossData;
  }

  private getSafetyScoreStatus(system: OverviewSystem): StatsValuesConfigInterface {
    const value = system.safetyState;

    const link = `safety`;

    if (!value) {
      return { ...safetyScoreValuesConfig.missing, link };
    }

    if (value === 'ACTION_REQUIRED') {
      return { ...safetyScoreValuesConfig.high, link };
    } else if (value === 'ACTION_RECOMMENDED') {
      return { ...safetyScoreValuesConfig.medium, link };
    } else {
      return { ...safetyScoreValuesConfig.balanced, link };
    }
  }

  private formatSystemOverviewData(data: OverviewSystem[]): SystemOverviewAntTableInterface[] {
    return data.map((system) => ({
      metadata: {
        id: system.id,
        name: system.name,
        location: system.metadata?.location,
        nominalEnergyCapacity: system.metadata?.nominalEnergyCapacity,
        maximumPower: system.metadata?.maximumPower,
        efcSum: system.kpis?.efcSum,
      },
      safetyScore: this.getSafetyScoreStatus(system),
      performance: !this.isSenecDemo ? this.getPerformanceStatus(system) : null,
      health: this.getHealthStatusBasedOnSohSpread(system),
      impact: !this.isSenecDemo ? this.getEnergyLossImpact(system) : [],
    }));
  }
}
