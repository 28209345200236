/* eslint-disable @typescript-eslint/naming-convention */
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { environment } from '@env/fe';
import { Store } from '@ngrx/store';
import { SystemsService } from '@twaice-fe/frontend/shared/services';
import { actions } from '@twaice-fe/frontend/shared/store';
import { ENERGY_BASE_ROUTE, MOBILITY_BASE_ROUTE, MODEL_LIBRARY_BASE_ROUTE } from '@twaice-fe/shared/constants';
import { CustomerType } from '@twaice-fe/shared/models';
import { distinctUntilChanged } from 'rxjs';

const {
  configsAction: { loadConfigSuccess },
} = actions;

@Component({
  selector: 'twaice-fe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  version = environment.version;
  featureFlags = {};

  constructor(
    private router: Router,
    private systemService: SystemsService,
    protected store: Store,
    private destroy$: DestroyRef
  ) {}

  ngOnInit(): void {
    this.router.initialNavigation();
    this.initSystemConfig();
  }

  getBaseUrl(customerType: CustomerType): string {
    switch (customerType) {
      case CustomerType.ENERGY:
        return ENERGY_BASE_ROUTE;
      case CustomerType.MOBILITY:
        return MOBILITY_BASE_ROUTE;
      case CustomerType.MODELS:
        return MODEL_LIBRARY_BASE_ROUTE;
    }

    console.error('Could not match config to customer type!');

    return '';
  }

  private initSystemConfig() {
    this.systemService
      .getSystemUiConfiguration()
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroy$))
      .subscribe((config) => {
        if (!config) return;

        const featureFlagList = config.featureFlags?.filter(Boolean);
        featureFlagList.forEach((flag) => (this.featureFlags[flag] = true));

        this.store.dispatch(
          loadConfigSuccess({
            featureFlags: featureFlagList,
            solutions: config.solutions,
            customerType: config.customerType,
            customerBk: config.customerBk,
            baseUrl: this.getBaseUrl(config.customerType),
          })
        );
      });
  }
}
