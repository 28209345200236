import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { DATATABLE } from '.';
import { DataDisplayModule } from '../data-display/data-display.module';
import { LoadingStripesModule } from '../loading-stripes/loading-stripes.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MenuModule } from '../menu/menu.module';
import { TagModule } from '../tag/tag.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSelectModule } from 'ng-zorro-antd/select';

const CORE = [CommonModule, FormsModule, ReactiveFormsModule, ScrollingModule];

const EXTERNAL_MODULES = [
  InfiniteScrollModule,
  LoadingStripesModule,
  DataDisplayModule,
  SvgIconModule,
  RouterModule,
  TagModule,
  TooltipModule,
  MenuModule,
  NzPaginationModule,
];

const MATERIAL_MODULES = [
  MatIconModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatCardModule,
  TooltipModule,
  MatSelectModule,
  MatMenuModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatRadioModule,
  MatChipsModule,
  MatSliderModule,
  MatButtonModule,
  NzDropDownModule,
  NzSelectModule,
];

@NgModule({
  declarations: [...DATATABLE],
  imports: [...CORE, ...MATERIAL_MODULES, ...EXTERNAL_MODULES],
  exports: [...DATATABLE],
  providers: [{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }],
})
export class DataTableModuleV2 {}
