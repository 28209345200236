import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { capitalizeFirstLetter, keysToSnake } from '@twaice-fe/shared/utilities';
import { Observable, Subject, debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs';
import { BaseStoreComponent } from '../base-store.component';
import { DatatableConfigInterface } from '../models/datatable.interface';
import { FilterTypeEnum } from '../models/filter-config.interface';
import { ListModeEnum } from '../models/list-mode.enum';

@Component({
  selector: 'twaice-fe-datatable-config',
  templateUrl: './datatable-config.component.html',
  styleUrls: ['./datatable-config.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatatableConfigComponent extends BaseStoreComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filterConfig = [];
  @Input() searchPlaceholder = '';
  @Input() isListLoading$: Observable<boolean>;
  @Input() cardEnabled = false;
  @Input() addActionBtnText;
  @Input() config: DatatableConfigInterface;
  @Input() updateRouteFilter: boolean;
  @Input() hasHeaderSection: boolean;

  tableDisplayMode$: Observable<string>;

  /**
   * Search form
   */
  searchForm: FormGroup;

  listModeEnum = ListModeEnum;

  columnKeys: Map<string, string> = new Map();

  columnSelectionForm: FormGroup;

  filterList: string[] = [];
  filterObject: Record<string, string>;

  /**
   * filled when all the subscribers needs to be destroyed
   * @type {Subject<boolean>}
   */
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected formBuilder: FormBuilder,
    protected store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(store);

    this.searchForm = this.formBuilder.group({
      searchTerm: '',
    });
  }

  ngOnInit(): void {
    this.handleTableModeChange();

    this.handleSearch();

    this.setColumnsConfig();

    this.handleFilterChipsChange();

    this.trackTableSearchString();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes.config === 'undefined') return;

    if (changes.config.previousValue !== changes.config.currentValue) {
      this.setColumnsConfig();
    }
  }

  formatFilterLabel(key: string, value: string) {
    for (const item of Object.keys(this.config)) {
      const filterObject = this.config[item]?.filter;
      if (filterObject?.type === FilterTypeEnum.TEXT && filterObject.filterBy === key) {
        return `${this.config[item].header} : ${capitalizeFirstLetter(value)}`;
      }

      if (filterObject?.type === FilterTypeEnum.NUMBER && [filterObject.from, filterObject.to].includes(key)) {
        return `${capitalizeFirstLetter(key)} : ${filterObject.format(Number(value))}`;
      }

      if (filterObject?.type === FilterTypeEnum.DATE && [filterObject.from, filterObject.to].includes(key)) {
        return `${capitalizeFirstLetter(key)} : ${new Date(value).toLocaleDateString('de-DE')}`;
      }

      if (
        (filterObject?.type === FilterTypeEnum.OPTIONS || filterObject?.type === FilterTypeEnum.SELECT) &&
        filterObject.filterBy === key
      ) {
        return `${capitalizeFirstLetter(key)} : ${capitalizeFirstLetter(value)}`;
      }
    }
  }

  removeFilter(key: string) {
    let filterKey = key.split(':')[0].trim().toLowerCase();
    const filters = Object.assign({}, this.filterObject);

    Object.keys(this.config).forEach((itemKey: string) => {
      if (this.config[itemKey].header === key.split(':')[0].trim()) {
        filterKey = this.config[itemKey].filter['filterBy'];
      }
    });

    delete filters[filterKey];

    this.store.dispatch(this.getConfigActions({ filter: filters }));

    if (this.updateRouteFilter) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { ...filters, [filterKey]: undefined },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }

  public keepOrder() {
    return 0;
  }

  ngOnDestroy(): void {
    this.store.dispatch(this.getConfigActions({ searchString: '' }));
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * Change the current display mode (list, card)
   *
   * @param mode
   */
  public changeMode(mode) {
    this.store.dispatch(this.getConfigActions({ listMode: mode }));
  }

  private setColumnsConfig(): void {
    this.store
      .select(this.getSelector())
      .pipe(
        filter(({ config: { columns } }) => !!columns),
        take(1)
      )
      .subscribe(({ config: { columns } }) => this.processColumns(columns));
  }

  private processColumns(columns: string[]): void {
    const toggleColumns = this.getToggleColumns(columns);
    toggleColumns.forEach((key) => this.columnKeys.set(key, this.config[key].header));
    this.initColumnPickerListener(columns);
    this.initColumnPicker(columns);
  }

  private getSelectedColumns(columns: string[]): string[] {
    return columns.filter((col) => !this.config[col].unselected);
  }

  private getToggleColumns(columns: string[]): string[] {
    return columns.filter((col) => this.config[col].canToggle !== false);
  }

  private getNonToggableColumns(columns: string[]): string[] {
    return columns.filter((col) => this.config[col].canToggle === false);
  }

  private initColumnPickerListener(columnList: string[]) {
    const toggleColumns = this.getToggleColumns(columnList);
    const nonTaggableColumns = this.getNonToggableColumns(columnList);

    // init columnSelectionForm like { [columName]: true }
    this.columnSelectionForm = this.formBuilder.group(
      toggleColumns.reduce((prev, curr) => ({ ...prev, ...{ [curr]: true } }), {})
    );

    this.columnSelectionForm.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((columns) => {
      const selectedColumns = Object.keys(columns).filter((key) => columns[key]);
      this.store.dispatch(this.getTableConfigActions({ columns: [...selectedColumns, ...nonTaggableColumns] }));
    });
  }

  private initColumnPicker(columnList: string[]) {
    const nonTaggableColumns = this.getNonToggableColumns(columnList);
    const selectedColumns = this.getSelectedColumns(columnList);
    const columns = [...new Set([...selectedColumns, ...nonTaggableColumns])];
    this.store.dispatch(this.getTableConfigActions({ columns }));
  }

  private handleTableModeChange() {
    this.tableDisplayMode$ = this.store
      .select<unknown>(this.getSelector())
      .pipe(map(({ config }) => config.listMode ?? ListModeEnum.LIST));
  }

  private handleSearch() {
    this.searchForm.controls['searchTerm'].valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map((searchTerm) => searchTerm ?? null),
        takeUntil(this.destroy$)
      )
      .subscribe((searchString) => {
        this.store.dispatch(this.getConfigActions({ searchString }));
      });
  }

  private trackTableSearchString() {
    this.store
      .select(this.getSelector())
      .pipe(
        filter((data) => data?.config?.searchString && data.config.searchString.length > 0),
        tap((data) => {
          const currentSearchTerm = this.searchForm.get('searchTerm')?.value;
          if (currentSearchTerm === '') {
            this.searchForm.patchValue({ searchTerm: data.config.searchString });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: (err) => console.error('Datatable search string tracking:', err),
      });
  }

  private handleFilterChipsChange() {
    this.store
      .select(this.getSelector())
      .pipe(
        map(({ config: { filter } }) => filter),
        takeUntil(this.destroy$)
      )
      .subscribe((filter) => {
        if (filter) {
          filter = keysToSnake(filter || {});
          this.filterObject = filter;
          this.filterList = Object.keys(filter)
            .filter((key: string) => filter[key])
            .map((key: string) => this.formatFilterLabel(key, filter[key]))
            .filter((label) => label);
        }
      });
  }
}
