/*
 * Compare function which takes into account numbers within the string
 *
 * @param {a} object/string - item 1 to compare
 * @param {b} object/string - item 2 to compare
 * @param {sortByProperty} string - [optional] if items are object, you can define what property to sort by
 * @return {number} - return of the sorting assessment
 * */
export function naturalCompare(a, b, sortByProperty?: string) {
  let stringA, stringB;

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    // if the input is a string, we compare the strings direclty
    stringA = a;
    stringB = b;
  } else {
    // if the input is an object we take a property name if available,
    // otherwise we pick name or type
    if (!sortByProperty && a.name && b.name) {
      sortByProperty = 'name';
    } else if (!sortByProperty) {
      sortByProperty = 'type';
    }

    if (typeof a[sortByProperty.toString()] === 'number' && typeof b[sortByProperty.toString()] === 'number') {
      return a[sortByProperty.toString()] - b[sortByProperty.toString()];
    }

    stringA = a[sortByProperty.toString()]?.toString() ?? '';
    stringB = b[sortByProperty.toString()]?.toString() ?? '';
  }

  return stringA.localeCompare(stringB, undefined, {
    numeric: true,
    sensitivity: 'base',
    ignorePunctuation: true,
  });
}

export const sortNullableNumbers = (a: number | null, b: number | null) => {
  if (a === null) return 1;
  if (b === null) return -1;
  return a - b;
};
