import { StorageLevel } from '@twaice-fe/shared/models';

export class StorageLevelIdentifierUtils {
  static toLevelString(
    level: StorageLevel,
    capitalized: boolean = false,
    componentCount: number | undefined = undefined,
    includeCount: boolean = false
  ): string {
    let levelIdentifier = level as string;
    if (componentCount !== 1) {
      levelIdentifier = `${level}s`;
    }
    if (capitalized) {
      levelIdentifier = levelIdentifier.charAt(0).toUpperCase() + levelIdentifier.slice(1);
    }
    return includeCount && componentCount ? `${componentCount} ${levelIdentifier}` : levelIdentifier;
  }
}
