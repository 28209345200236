<div class="datatable-card">
  <twaice-fe-datatable-config
    *ngIf="enableTableConfig"
    [uniqueStateName]="uniqueStateName"
    [searchPlaceholder]="searchPlaceholder"
    [cardEnabled]="cardEnabled"
    [addActionBtnText]="addActionBtnText"
    [isListLoading$]="isLoading$"
    [config]="config"
    [updateRouteFilter]="updateRouteFilter"
    [hasHeaderSection]="hasHeaderSection"
  ></twaice-fe-datatable-config>

  <twaice-fe-bulk-actions-bar
    [uniqueStateName]="uniqueStateName"
    *ngIf="enableBulkSelection"
    [maxSelectLimit]="maxSelectLimit"
    [bulkActions]="bulkActions"
    [bulkSelection]="bulkSelection$ | async"
    [totalItemCount]="(elements$ | async)?.totalCount ?? (elements$ | async)?.length"
    (selectedAll)="onBulkSelectAll($event)"
  ></twaice-fe-bulk-actions-bar>

  <ng-container>
    <!-- Start of custom list -->
    <div *ngIf="this.mode === listModeEnum.CUSTOM_LIST; else cardTemplate">
      <cdk-virtual-scroll-viewport itemSize="50" class="h-[200px] w-[300px]">
        <div *cdkVirtualFor="let item of (elements$ | async)?.data ?? (elements$ | async); cdkVirtualForTrackBy: trackChanges">
          <ng-template
            [ngTemplateOutlet]="customListTemplate"
            [ngOutletContext]="{
              $implicit: item
            }"
          ></ng-template>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
    <!-- End of custom list -->

    <ng-template #cardTemplate>
      <!-- Start Card template -->
      <!-- scroll container is referencing dashboard layout scrolling container -->
      <div
        *ngIf="(tableDisplayMode$ | async) === listModeEnum.CARD"
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="1200"
        [infiniteScrollDisabled]="reachedLastPage$ | async"
        infiniteScrollContainer=".router-content"
        [fromRoot]="true"
        (scrolled)="onScroll()"
      >
        <div class="card-list mt-16" *ngIf="((elements$ | async)?.totalCount ?? (elements$ | async)?.length) > 0">
          <div *ngFor="let item of (elements$ | async)?.data ?? (elements$ | async)">
            <ng-template
              [ngTemplateOutlet]="cardsTemplate"
              [ngTemplateOutletContext]="{ $implicit: item, columns: columns$, content: config }"
            ></ng-template>
          </div>
        </div>

        <ng-container *ngIf="isLoading$ | async">
          <div class="card-list mt-16">
            <div *ngFor="let item of loadingStripes">
              <div card template="loading"></div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- End of card template -->

      <!-- Start of table template -->
      <!-- scroll container is referencing dashboard layout scrolling container -->
      <div
        class="datatable h-full"
        [ngClass]="computedClasses"
        *ngIf="(tableDisplayMode$ | async) === listModeEnum.LIST"
        [infiniteScroll]="!frontEndPagination ? true : null"
        [infiniteScrollDistance]="!frontEndPagination ? 2 : null"
        [infiniteScrollThrottle]="!frontEndPagination ? 1200 : null"
        [infiniteScrollDisabled]="!frontEndPagination ? (reachedLastPage$ | async) : null"
        [infiniteScrollContainer]="!frontEndPagination ? '.datatable' : null"
        [fromRoot]="!frontEndPagination ? true : null"
        (scrolled)="!frontEndPagination ? onScroll() : null"
      >
        <ng-container *ngIf="enableVirtualScroll">
          <cdk-virtual-scroll-viewport #scrollViewport itemSize="100" [ngClass]="computedClasses" class="w-full">
            <table class="datatable" aria-label="Data table">
              <thead class="bg-white shadow-default" [style.top]="inverseOfTranslation">
                <col />
                <tr *ngIf="hasHeaderSection">
                  <th *ngIf="enableBulkSelection" scope="colgroup"></th>
                  <th class="new-head" scope="colgroup"></th>

                  <ng-container *ngFor="let config of headerSections$ | async">
                    <th [attr.colspan]="config.section.colspan" scope="colgroup">
                      <div class="header p-16 cursor-default">
                        <twaice-fe-svg-icon *ngIf="config.section.icon" [name]="config.section.icon" />
                        <span class="p-0">{{ config.section.title }}</span>
                      </div>
                    </th>
                  </ng-container>
                </tr>
                <tr>
                  <th *ngIf="enableBulkSelection" scope="col"></th>
                  <th class="new-head" scope="col"></th>
                  <ng-container *ngFor="let column of columns$ | async">
                    <th [ngClass]="config[column]?.style" scope="col">
                      <div class="header cursor-default" [ngClass]="config[column]?.style">
                        <span>{{ config[column]?.header || '- ' + column + ' -' }}</span>

                        <mat-icon
                          *ngIf="config[column]?.headerTooltip"
                          data-test="info-icon"
                          color="accent"
                          [twaiceFeTooltip]="config[column]?.headerTooltip"
                        >
                          info_outline
                        </mat-icon>

                        <ng-container *ngIf="config[column]?.sortableByKey">
                          <mat-icon
                            class="cursor-pointer"
                            (click)="sortColumn(column)"
                            *ngIf="(orderDirection$ | async) === 'asc' && (orderColumn$ | async) === column; else descending"
                            >keyboard_arrow_up</mat-icon
                          >
                          <ng-template #descending>
                            <mat-icon class="cursor-pointer" (click)="sortColumn(column)">keyboard_arrow_down</mat-icon>
                          </ng-template>
                        </ng-container>

                        <ng-container *ngIf="config[column]?.filter">
                          <twaice-fe-datatable-filter
                            [data]="config[column]?.filter"
                            [uniqueStateName]="uniqueStateName"
                            [updateRouteFilter]="updateRouteFilter"
                          />
                        </ng-container>
                      </div>
                    </th>
                  </ng-container>
                </tr>
              </thead>

              <tbody>
                <ng-template
                  *cdkVirtualFor="let item of (elements$ | async)?.data ?? (elements$ | async)"
                  [ngTemplateOutlet]="listTemplate"
                  [ngTemplateOutletContext]="{
                    $implicit: item,
                    mapped: item,
                    columns: columns$,
                    content: config,
                    enableBulkSelection: enableBulkSelection,
                    onBulkSelection: onBulkSelection.bind(this),
                    bulkSelected: !!bulkSelection$.value[item.id ?? item.containerID]
                  }"
                ></ng-template>

                <ng-container *ngIf="isStripLoading$ | async">
                  <ng-container *ngFor="let number of loadingStripes">
                    <tr class="loading-row">
                      <td *ngIf="enableBulkSelection"></td>
                      <td></td>
                      <ng-container *ngFor="let column of columns$ | async">
                        <td>
                          <twaice-fe-loading-stripe size="sm"></twaice-fe-loading-stripe>
                        </td>
                      </ng-container>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </cdk-virtual-scroll-viewport>
        </ng-container>

        <ng-container *ngIf="!enableVirtualScroll">
          <table class="datatable" aria-label="Data table">
            <thead class="bg-white shadow-default">
              <col />
              <tr *ngIf="hasHeaderSection">
                <th *ngIf="enableBulkSelection" scope="colgroup"></th>
                <th class="new-head" scope="colgroup"></th>

                <ng-container *ngFor="let config of headerSections$ | async">
                  <th [attr.colspan]="config.section.colspan" scope="colgroup">
                    <div class="header p-16 cursor-default">
                      <twaice-fe-svg-icon *ngIf="config.section.icon" [name]="config.section.icon" />
                      <span class="p-0">{{ config.section.title }}</span>
                    </div>
                  </th>
                </ng-container>
              </tr>
              <tr>
                <th *ngIf="enableBulkSelection" scope="col"></th>
                <th class="new-head" scope="col"></th>
                <ng-container *ngFor="let column of columns$ | async">
                  <th [ngClass]="config[column]?.style" scope="col">
                    <div class="header cursor-default" [ngClass]="config[column]?.style">
                      <span>{{ config[column]?.header || '- ' + column + ' -' }}</span>

                      <mat-icon
                        *ngIf="config[column]?.headerTooltip"
                        data-test="info-icon"
                        color="accent"
                        [twaiceFeTooltip]="config[column]?.headerTooltip"
                      >
                        info_outline
                      </mat-icon>

                      <ng-container *ngIf="config[column]?.sortableByKey">
                        <mat-icon
                          class="cursor-pointer"
                          (click)="sortColumn(column)"
                          *ngIf="(orderDirection$ | async) === 'asc' && (orderColumn$ | async) === column; else descending"
                          >keyboard_arrow_up</mat-icon
                        >
                        <ng-template #descending>
                          <mat-icon class="cursor-pointer" (click)="sortColumn(column)">keyboard_arrow_down</mat-icon>
                        </ng-template>
                      </ng-container>

                      <ng-container *ngIf="config[column]?.filter">
                        <twaice-fe-datatable-filter
                          [data]="config[column]?.filter"
                          [uniqueStateName]="uniqueStateName"
                          [updateRouteFilter]="updateRouteFilter"
                        />
                      </ng-container>
                    </div>
                  </th>
                </ng-container>
              </tr>
            </thead>

            <tbody>
              <ng-container *ngIf="((elements$ | async)?.totalCount ?? (elements$ | async)?.length) > 0">
                <ng-template
                  *ngFor="let item of (elements$ | async)?.data ?? (elements$ | async); trackBy: trackChanges"
                  [ngTemplateOutlet]="listTemplate"
                  [ngTemplateOutletContext]="{
                    $implicit: item,
                    mapped: item,
                    columns: columns$,
                    content: config,
                    enableBulkSelection: enableBulkSelection,
                    onBulkSelection: onBulkSelection.bind(this),
                    bulkSelected: !!bulkSelection$.value[item.id ?? item.containerID]
                  }"
                ></ng-template>
              </ng-container>

              <ng-container *ngIf="isLoading$ | async">
                <ng-container *ngFor="let number of loadingStripes">
                  <tr class="loading-row">
                    <td *ngIf="enableBulkSelection"></td>
                    <td></td>
                    <ng-container *ngFor="let column of columns$ | async">
                      <td>
                        <twaice-fe-loading-stripe size="sm"></twaice-fe-loading-stripe>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>

          <nz-pagination
            *ngIf="frontEndPagination"
            class="mt-13 float-right py-8"
            [nzPageIndex]="1"
            [nzTotal]="frontTotalItems"
            [nzPageSize]="pageSize"
            [nzHideOnSinglePage]="true"
            (nzPageIndexChange)="handleFrontPaginationPageChange($event)"
          ></nz-pagination>
        </ng-container>
      </div>

      <!-- End of table template -->
    </ng-template>
  </ng-container>

  <!-- Empty table or list  -->
  <div *ngIf="((elements$ | async)?.totalCount ?? (elements$ | async)?.length) === 0 && (isLoading$ | async) === false">
    <div class="no-results">
      {{ '0 results found' }}
    </div>
  </div>

  <span id="end_of_page"></span>
</div>
