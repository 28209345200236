import { Pipe, PipeTransform } from '@angular/core';
import { StorageLevel } from '@twaice-fe/shared/models';
import { StorageLevelIdentifierUtils } from './storege-level-identifyer.utils';

@Pipe({
  name: 'levelToLevelIdentifier',
  pure: true,
  standalone: true,
})
export class StorageLevelIdentifierPipe implements PipeTransform {
  transform(
    level: StorageLevel,
    capitalized: boolean = false,
    componentCount: number | undefined = undefined,
    includeCount: boolean = false
  ): string {
    return StorageLevelIdentifierUtils.toLevelString(level, capitalized, componentCount, includeCount);
  }
}
