import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Breadcrumb, BreadcrumbsService } from './breadcrumbs.service';
import { ComponentNameTranslationPipe, LevelNameTranslationPipe } from '@twaice-fe/frontend/shared/services';

@Component({
  selector: 'twaice-fe-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
  providers: [ComponentNameTranslationPipe, LevelNameTranslationPipe],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  breadcrumbs: Breadcrumb[];
  destroy$ = new Subject<void>();

  constructor(
    private breadcrumbService: BreadcrumbsService,
    private componentNameTranslationPipe: ComponentNameTranslationPipe,
    private levelNameTranslationPipe: LevelNameTranslationPipe
  ) {}

  ngOnInit(): void {
    this.breadcrumbService
      .getBreadcrumbs()
      .pipe(takeUntil(this.destroy$))
      .subscribe((breadcrumbs) => {
        this.breadcrumbs = breadcrumbs;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  transformBreadcrumb(value: string): string {
    const mappedComponentValue = this.componentNameTranslationPipe.transform(value);
    if (value !== mappedComponentValue) {
      return mappedComponentValue;
    }

    return this.levelNameTranslationPipe.transform(value);
  }
}
